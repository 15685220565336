import React, { useState } from 'react';
import imageStackStyles from './imageStackStyles.module.css';
import sail_post from '../../assets/sail_post.png';
import sail_itinerary from '../../assets/sail_itinerary.png';
import sail_itinerary_item from '../../assets/sail_itinerary_item.png';

const ImageStack = () => {
  const [selectedImage, setSelectedImage] = useState(sail_itinerary);
  const [hoveredImage, setHoveredImage] = useState(null);

  const images = [
    sail_post,
    sail_itinerary,
    sail_itinerary_item
  ];
  const rotations = [-20, 0, 20];
  const translations = [80, 0, -80];
  const handleClick = (image) => {
    setSelectedImage(image);
  };

  return (
    <div style={{maxWidth: '50%', display: 'flex', flexDirection: 'row', justifyContent:'center'}}>
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`${index}`}
          className={imageStackStyles.image}
          style={{
            zIndex: image === selectedImage ? 1 : 0,
            transform: `translateX(${translations[index]}px) rotate(${rotations[index]}deg) scale(${image === selectedImage ? 1.15 : (image === hoveredImage ? 1.05 : 1)})`,
            // boxShadow: image === selectedImage ? '0px 0px 20px 0px rgba(0,0,0,0.1)' : '0px 0px 20px 0px rgba(0,0,0,0.35)',
            // dropShadow: image === selectedImage ? '0px 0px 20px 0px rgba(0,0,0,0.1)' : '0px 0px 20px 0px rgba(0,0,0,0.35)',
            filter: image === selectedImage ? 'none' : 'blur(2px)'
          }}
          onClick={() => handleClick(image)}
          onMouseEnter={() => setHoveredImage(image)}
          onMouseLeave={() => setHoveredImage(null)}
        />
      ))}
    </div>
  );
};

export default ImageStack;