import React, { forwardRef,useState, useEffect } from "react";
import about_styles from "./features_styles.module.css"
// import Toolbar from "../components/toolbar"
// import Footer from "../components/footer"
// use react flickity carousel
// import Flickity from 'react-flickity-component'
// import 'flickity/css/flickity.css';
import Card from "./card"
import palm_leaves_1 from "../../assets/palm_leaves_1.png"
import palm_leaves_2 from "../../assets/palm_leaves_2.png"
import sail_itinerary_tilted from "../../assets/sail_itinerary_tilted.png"
import sail_retro from "../../assets/sail_retro.png"
import network from "../../assets/network.png"
import sail_sign from "../../assets/sail_sign.png"
import sail_maps from "../../assets/sail_maps.png"
import sail_messages from "../../assets/sail_messages.png"
import sail_journal from "../../assets/sail_journal.png"
import sail_blue_background from "../../assets/sail_blue_background.png"
import sail_iridescent_sticker from "../../assets/sail_iridescent_sticker.png"




const Features = forwardRef((props, ref) => {
  const xOffsetsMobile = [0, 0, 0, 0, 0, 0]
  const xOffsetsDesktop = [100, -250, 0, 100, 100, 0]
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [xOffsets, setXOffsets] = useState(isMobile ? xOffsetsMobile : xOffsetsDesktop);
  
  const xMarginsMobile = [0, 0, 0, 0, 0, 0]
  const xMarginsDesktop = [200, 200, -100, -200, 50, 50]
  const [margins, setMargins] = useState(isMobile ? xMarginsMobile : xMarginsDesktop);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setXOffsets(isMobile ? xOffsetsMobile : xOffsetsDesktop);
      setMargins(isMobile ? xMarginsMobile : xMarginsDesktop);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile, xOffsetsMobile, xOffsetsDesktop, xMarginsMobile, xMarginsDesktop]);

    return (
        <div ref={ref} style={{'paddingTop': 75}}>
      <div className={about_styles.main}>
        <div className={about_styles.content}>
          <div className={about_styles.shadows}>
            <img src={palm_leaves_2} alt="shadows2" style={{marginLeft: "auto"}}/>
            <img src={palm_leaves_1} alt="shadows1" style={{transform: "scaleX(-1)"}}/>
          </div>
          <h1 type="title">Feature Rich</h1>
          <p>A little of something for everyone. Actually, a lot of something for everyone.</p>
          <div className={about_styles.carouselWrapper} >
          <Card rotate={"7deg"} margin={`0 ${margins[0]} 0 0`} sticker={sail_iridescent_sticker} transform={`translate(${xOffsets[0]}px, -100px)`}>
          <div style={{backgroundColor: "", padding: "10px"}}>
            <p style={{ margin:'0',fontWeight: "bold", fontSize: "20px", marginBottom:"15px"}}>Top-tier itinerary builder</p>
          </div>
          <div>
            <img src={sail_itinerary_tilted} alt="itinerary builder" style={{width: "80%"}} />

          </div>
          </Card>
          <Card rotate={'-7deg'} margin={`-20px 0 0 ${margins[1]}`} sticker={sail_retro} transform={`translate(${xOffsets[1]}px, -100px)`}>
          <div style={{backgroundColor: "", padding: "10px"}}>
            <p style={{ margin:'0',fontWeight: "bold", fontSize: "20px", marginBottom:"15px"}}>Social Network</p>
            <p style={{ margin:'0', fontSize: "16px"}}>Connect with your friends and share itineraries</p>
          </div>
          <div>
            <img src={network} alt="network" style={{width: "100%"}} />

          </div>
          </Card>
          <Card rotate={'7deg'} margin={`0 0 0 ${margins[2]}`} transform={`translate(${xOffsets[2]}px, -80px)`}>
          <div style={{backgroundColor: "", padding: "10px"}}>
            <p style={{ margin:'0',fontWeight: "bold", fontSize: "20px", marginBottom:"15px"}}>Smart Suggestions</p>
            <p style={{ margin:'0', fontSize: "16px"}}>Finding your perfect vacation is a breeze with our intellegent feed</p>
          </div>
          <div>
            <img src={sail_sign} alt="AI" style={{width: "100%"}} />
          </div>
          </Card>
          <Card rotate={'-7deg'} margin={`0 0 0 ${margins[3]}`} sticker={sail_blue_background} transform={`translate(${xOffsets[3]}px, -80px)`}>
          <div style={{backgroundColor: "", padding: "10px"}}>
            <p style={{ margin:'0',fontWeight: "bold", fontSize: "20px", marginBottom:"15px"}}>Rich Maps Integration</p>
            <p style={{ margin:'0 0 -60px 0', fontSize: "16px", zIndex:10, position: "relative"}}>Our maps are rich with information and will help every step of the planning process</p>
          </div>
          <div>
            <img src={sail_maps} alt="AI" style={{margin:0, zIndex:1, filter: "drop-shadow(0px 2px 2px #00000000)", width: "100%" }} />
          </div>
          </Card>
          <Card rotate={'6deg'} margin={`0 0 0 ${margins[4]}`} transform={`translate(${xOffsets[4]}px, -80px)`}>
          <div style={{backgroundColor: "", padding: "10px"}}>
            <p style={{ margin:'0',fontWeight: "bold", fontSize: "20px", marginBottom:"15px"}}>In-App Messaging</p>
            <p style={{ margin:'0 0 0px 0', fontSize: "16px", zIndex:10, position: "relative"}}>Planning with friends has never been this easy</p>
          </div>
          <div>
            <img src={sail_messages} alt="AI" style={{marginTop:"50px", zIndex:1, filter: "drop-shadow(0px 2px 2px #00000050)", width: "100%"}} />
          </div>
          </Card>
          <Card rotate={'-6deg'} margin={`0 0 0 ${margins[5]}`} transform={`translate(${xOffsets[5]}px, -100px)`}>
          <div style={{backgroundColor: "", padding: "10px"}}>
            <p style={{ margin:'0',fontWeight: "bold", fontSize: "20px", marginBottom:"15px"}}>Travel Journal</p>
            <p style={{ margin:'0 0 0px 0', fontSize: "16px", zIndex:10, position: "relative"}}>Use Sail as a scrapbook to remember your adventures</p>
          </div>
          <div>
            <img src={sail_journal} alt="journal" style={{ zIndex:1, filter: "drop-shadow(0px 5px 7px #00000050)", width: "100%" }} />
          </div>
          </Card>
        </div>
        </div>
      </div>
      </div>
    );
  });
  
  export default Features;