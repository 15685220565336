import React from 'react';
import { useMediaQuery } from 'react-responsive';
import ImageStack from './imagestack';
import feature_styles from './features.module.css';
import Badge from '../../components/badge';
import { HeartFill, Fire } from 'react-bootstrap-icons';
import chair from '../../assets/chair.png';

const Features = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1000px)'
  });
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' });

  return (
    <div>
      <div className={feature_styles.feature}>
        <p className={feature_styles.para}>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <h2 style={{textAlign:"left"}}>Craft the <Badge word="perfect" color={"#FF6D6D"} IconComponent={HeartFill}/> itinerary with ease</h2></div>
 
              Planning the perfect vacation has never been easier with Sail. We've revolutionized the way you plan your trips, making the entire process effortless and enjoyable.
              <br/><br/>
              Sail makes vacation planning easy and fun. We change how you plan trips, <b>making it smooth and enjoyable</b>.
Get set for a perfect vacation with Sail. We guide you to amazing travels. Start exploring and enjoy your dream adventure.

        </p>
        <ImageStack />
      </div>
     {!isMobile &&

      <div style={{margin: "5em 0 0 0", display: "flex", justifyContent: "center"}}>
        <hr style={{width: "50%", borderBottom: "2px solid #d5d5d5", borderTop: "none", borderLeft: "none", borderRight: "none"}}/>
      </div>

     }
      <div className={feature_styles.feature}>
        {isDesktopOrLaptop && <img src={chair} type="feature_img" alt="man in chair" />}
        <p className={feature_styles.para}>
            <h2 style={{textAlign:"left"}}>Avoid vacation planning <Badge word="burnout" color={"#FF7B00"} IconComponent={Fire}/></h2>
            Planning a vacation can be stressful, but it doesn't have to be. <b>Sail takes the stress out of planning your trip</b>, so you can focus on enjoying your vacation.
        </p>
        {isMobile && <img src={chair} type="feature_img" alt="Sail Hero" />}
      </div>
    </div>
  );
};

export default Features;