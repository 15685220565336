import React from 'react';
import Header from './header';


const PrivacyPolicy = () => {

  return (
    <div className={`privacy-policy-container`} style={{marginTop: "100px", width: "100%"}}>
      <Header />
      
      <p> 
        <body>
        <div className='privacy-policy' style={{textAlign:"left", padding:"20px", maxWidth:"600px", margin: "auto"}}>
        <h1 type="title" style={{fontSize:"50px", textAlign:"center"}}>Privacy Policy</h1>
          <h3><b>Introduction</b></h3>
          <br />
          <b>Last updated: 8/2024 </b>
          <br   />
          <br />
Welcome to Sail Vacations, a Sail LLC app. 
<br />
<br />
<b>Location Data</b>
<br />
To provide you with the core functionality of Sail, we use, but do not store your location data. This data is used solely to identify points of interest in a middle area between your current location and another location you specify.
<br />
<br />
<b>
This data never enters our servers or any third-party services.</b>
<br />
<br />
We do not store your location data. The location information is used in real-time to provide you with relevant points of interest suggestions and is not retained by our servers or any third-party services.
<br />
<br />
<b>How We Use Your Information</b>
<br />
Finding Points of Interest: Your location data is processed in real-time to find points of interest that are conveniently located between your current location and another specified location. This service is completed through the Apple Maps service.
<br />
<br />
Enhancing User Experience: By using your location data, we aim to provide a seamless and efficient user experience, ensuring that you receive accurate and relevant restaurant suggestions.
<br />
<br />
<b>Data Security</b>
<br />
We implement robust security measures to protect your location data during transmission. Our application uses encryption protocols to ensure that your data is secure and protected from unauthorized access.
<br />
<br />

<b>No Data Retention</b>
<br />
As stated, we do not store your location data. Once the necessary calculations are performed to find the middle area points of interest, your location data is discarded.
<br />
<br />
<b>Third-Party Services</b>
<br />
We do not share your location data with any third-party services. Your privacy is of utmost importance to us, and we ensure that your data is used exclusively within the Sail application for the purposes described in this policy.
<br />
<br />
<b>User Rights</b>
<br />
Since we do not store your location data, there is no stored information for you to access, modify, or delete. You have full control over your location data by choosing when to provide it to the application.
<br />
<br />
<b>Changes to This Privacy Policy</b>
<br />

Sail LLC reserves the right to update this Privacy Policy at any time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
<br />
<br />
</div>
        </body>
</p>
    </div>
  );
};

export default PrivacyPolicy;