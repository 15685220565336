import React, { useState, useEffect, useRef } from "react";
import styles from "./card.module.css";

const Card = ({ sticker, transform, children, rotate, margin }) => {
    const stickerStyle = {
        position: 'absolute',
        zIndex: 2,
        rotate: '-2deg',
        transform: `${transform} `, 
        width: '40%',
        height: '40%',
    };



    return (
        <div
            className={styles.card}
            style={{
                transform: `rotate(${rotate})`,
                margin: margin,
                transition: 'transform 0.2s ease-out',
            }}
        >
            <div className={styles.cardContent}>
                {children}
                {sticker && <img src={sticker} alt="sail_logo" style={stickerStyle} />}
            </div>
        </div>
    );
};

export default Card;