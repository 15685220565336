import React, { useState, useEffect } from 'react';
import first_class from "./first_class_details.module.css"
import Detail from "./detail";
import suggestions_picture from "../assets/suggestions_picture.webp";
import calendar_integrations from "../assets/calendar.webp";
import packinglist from "../assets/packinglist.webp";

const FirstClassDetails = () => {
    const [selected, setSelected] = useState("detail_1");
    const [transitionClass, setTransitionClass] = useState("");


    const details = {
        "detail_1": ["AI Itinerary", "The most intelligent travel agent (and travel guide) now fits in your pocket. The AI enabled suggestions fills your itinerary no matter the place and time.", true, suggestions_picture],
        "detail_2": ["Calendar", "Keep your schedule organized by integrating Sail with you favorite calendar.", false, calendar_integrations],
        "detail_3": ["Packing List", "Whether it's your skis or your snorkle, never forget anything at home again. The smart parking list will intelligently suggest items to pack based on your itinerary.", false, packinglist],
        "detail_4": ["Notifications", "This is the Notifications", false]
    }

    useEffect(() => {
        if (transitionClass === "") return;

        const timer = setTimeout(() => {
            setTransitionClass("");
        }, 300); // Match the duration of the CSS transition

        return () => clearTimeout(timer);
    }, [transitionClass]);

    const handleDetailChange = (detail) => {
        if (selected === detail) return;
        setTransitionClass(first_class.exiting);
        setSelected(detail)
        setTimeout(() => {
            ;
            setTransitionClass(first_class.entering);
        }, 200); // Match the duration of the CSS transition
    };

    return (
        <div style={{backgroundColor: "black", width: "100vw"}}>
        <div style={{display: "flex", justifyContent: "center"}}>
            <hr style={{width: "50%", borderBottom: "2px solid #353535", borderTop: "none", borderLeft: "none", borderRight: "none"}}/>
        </div>
        <div className={first_class.firstClassDetails}>
            
            <div className={first_class.banner}>
                <button type="detail" className={selected === "detail_1" ? first_class.selected : ""} onClick={() => handleDetailChange("detail_1")}>AI Itinerary</button>
                <button type="detail" className={selected === "detail_2" ? first_class.selected : ""} onClick={() => handleDetailChange("detail_2")}>Calendar</button>
                <button type="detail" className={selected === "detail_3" ? first_class.selected : ""} onClick={() => handleDetailChange("detail_3")}>Packing List</button>
                {/* <button type="detail" className={selected === "detail_4" ? first_class.selected : ""} onClick={() => handleDetailChange("detail_4")}>Notifications</button> */}
            </div>
            <div style={{width: "400px"}}>
                <div className={`${first_class.details} ${transitionClass}`}>
                    <Detail detail={details[selected]} />
                </div>
            </div>
            
        </div>
        </div>
    );
}

export default FirstClassDetails;
