import React, { useRef } from 'react';
import Hero from './components/hero';
import Features from './components/features';
import Footer from '../components/footer';
import FirstClass from './components/first_class';
import FeaturesSection from './features/features';

const Index = () => {
  const featuresRef = useRef(null);
  const firstClassRef = useRef(null);

  const scrollToFeatures = () => {
    if (featuresRef.current) {
      featuresRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const scrollToFirstClass = () => {
    if (firstClassRef.current) {
      firstClassRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <Hero onFeaturesClick={scrollToFeatures} onFirstClassClick={scrollToFirstClass}/>
      <Features />
      <FirstClass ref={firstClassRef}/>
      <FeaturesSection ref={featuresRef}/>
      <Footer />
    </div>
  );
};

export default Index;