import React from "react";

const Detail = ({detail}) => {
    return (
        <div style={{minHeight: "400px", display: "flex", flexDirection: "column", alignContent: "center", justifyContent:"center", width: "400px"}}>
            
            <h2 style={{lineHeight: "25px", fontSize: "2rem"}}>
                {detail[0]}
            </h2>
            
            <p>
                {detail[1]}
            </p>
            <img src={detail[3]} alt="" style={{width: "300px", margin: "auto"}}/>
            {!detail[2] && (
                <p style={{color: "#b1b1b1", marginTop: "100px", fontSize: "8pt"}}>
                    SOME FEATURES COMING SOON
                </p>
            )}
            
            
        </div>
    )
}

export default Detail;
