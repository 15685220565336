import React, { useState, useEffect, useRef } from 'react';
import styles from './badge.module.css';

const getRandomOffset = (max, min) => {
  // Generate a random offset within the badge dimensions
  return `${Math.floor(Math.random() * (max - min)) + min}px`;
};


const useFloatingIcons = (IconComponent, badgeRef, count = 6, color, darkerColor) => {
    const [icons, setIcons] = useState([]);
  
    useEffect(() => {
      if (badgeRef.current) {
        const { width, height } = badgeRef.current.getBoundingClientRect();
        setIcons(Array.from({ length: count }, () => ({
          id: Math.random(),
          top: getRandomOffset(height),
          left: getRandomOffset(width),
        })));
      }
    }, [badgeRef, count]);
  
    useEffect(() => {
      const interval = setInterval(() => {
        if (badgeRef.current) {
          const { width, height } = badgeRef.current.getBoundingClientRect();
          setIcons(icons.map(icon => ({
            ...icon,
            top: getRandomOffset(height+10, -10),
            left: getRandomOffset(width+10, -10),
          })));
        }
      }, 2000); // Adjust time for movement speed
  
      return () => clearInterval(interval);
    }, [icons, badgeRef]);
  
    return icons.map(icon => (
        <IconComponent key={icon.id} style={{ 
            top: icon.top, 
            left: icon.left, 
            position: 'absolute', 
            transition: 'top 2s cubic-bezier(0.175, 0.885, 0.32, 1.575), left 2s cubic-bezier(0.175, 0.885, 0.32, 1.575)', 
            color: color,
            dropShadow: `0px -50px 50px ${darkerColor}, inset 0px -2px 3px ${darkerColor}, inset 0px 2px 3px ${color}`,
            zIndex: -1,
            cursor: 'text',
          }} className={styles.icon} />
    ));
  };

const Badge = ({ word, color, textColor="black", textStyle, IconComponent }) => {
  const badgeRef = useRef(null);
  

  const darkerColor = `rgba(${parseInt(color.slice(-6, -4), 16) - 40}, ${parseInt(color.slice(-4, -2), 16) - 40}, ${parseInt(color.slice(-2), 16) - 40})`;
  const lighterColor = `rgba(${parseInt(color.slice(-6, -4), 16) + 40}, ${parseInt(color.slice(-4, -2), 16) + 40}, ${parseInt(color.slice(-2), 16) + 40})`;
  const floatingIcons = useFloatingIcons(IconComponent, badgeRef, 10, color, darkerColor);
  return (
    <div className={styles.badgeContainer}>
      <div ref={badgeRef} className={styles.badge} style={{ 
          backgroundColor: color, 
          color: textColor,  
          boxShadow: `0px 5px 30px ${darkerColor}, inset 0px -2px 3px ${darkerColor}, inset 0px 2px 3px ${lighterColor}`,
          border: `2px solid ${color}`,
          position: 'relative',
          zIndex: 10,
        //   make it italic
          fontStyle: textStyle,
          cursor: 'text',
      }}>

        <b>{word}</b>
        {floatingIcons}
      </div>
    </div>
  );
};

export default Badge;