import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from './header';
import styles from './contact.module.css';
import { getDatabase, ref, push } from 'firebase/database'; // Import necessary functions
import { app } from './firebaseConfig'; // Import your Firebase app

const Contact = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const database = getDatabase(app); // Initialize the database

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted');
    try {
      await push(ref(database, 'contacts'), {
        email,
        message,
      });
      console.log('Data written to database');
      navigate('/');
    } catch (error) {
      console.error('Error writing to database', error);
    }
  };

  return (
    <div style={{ marginTop: "100px", width: "100%" }}>
      <Header />
      <div className='privacy-policy' style={{ textAlign: "left", padding: "20px", maxWidth: "600px", margin: "auto" }}>
        <h1 type="title" style={{ fontSize: "50px", textAlign: "center" }}>Contact Us</h1>
        <h3><b>Support Form</b></h3>
        <br />
        <form onSubmit={handleSubmit}>
          <b>Email:</b>
          <br />
          <input
            type="text"
            style={{ width: '100%' }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <b>What can we help with?</b>
          <textarea
            style={{ width: '100%' }}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button type='submit'>Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Contact;