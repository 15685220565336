import React, { useRef, useState, useEffect } from 'react';
import hero_styles from './hero.module.css';
import Toolbar from '../../components/toolbar';
import { ArrowRight } from 'react-bootstrap-icons';
import logo from '../../assets/logo.svg';
import hero from '../../assets/hero.png';
import AppStore from '../../assets/appstore.svg';

const Hero = ({ onFeaturesClick, onFirstClassClick }) => {
  const logoDivRef = useRef(null);
  const titleH1Ref = useRef(null);
  const buttonDivRef = useRef(null);
  const [totalHeight, setTotalHeight] = useState(0);

  useEffect(() => {
      const logoDivHeight = logoDivRef.current ? logoDivRef.current.offsetHeight : 0;
      const titleH1Height = titleH1Ref.current ? titleH1Ref.current.offsetHeight : 500;
      const buttonDivHeight = buttonDivRef.current ? buttonDivRef.current.offsetHeight : 0;

      const total = logoDivHeight + titleH1Height + buttonDivHeight;
      setTotalHeight(total);
      console.log('total height', total);
  }, []);
  return (
    <div className={hero_styles.container}>
      <div style={{width: '65%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
          <Toolbar index={true} totalHeight={totalHeight} onFeaturesClick={onFeaturesClick} onFirstClassClick={onFirstClassClick}/>

          <h1 ref={titleH1Ref}   type="title">The Ultimate Travel Companion</h1>
          <p style={{'textAlign': 'center'}}>Organize your itinerary. Collaborate with friends. Explore your dream vacations. All in one app.</p>
          <p style={{'textAlign': 'center'}}><b>  </b></p>
          <div style={{ position: 'relative', display: 'inline-block' }}>
          {/* <span style={{
              position: 'absolute',
              top: '-10px',
              left: '50%',
              transform: 'translateX(-50%)',
              backgroundColor: 'rgba(255, 103, 0, 0.5)',
              color: 'white',
              padding: '2px 8px',
              borderRadius: '10px',
              fontSize: '12px',
              fontWeight: 'bold',
              zIndex: 1,
              backdropFilter: 'blur(5px)', // Background blur
              WebkitBackdropFilter: 'blur(5px)', // Safari background blur
              border: '1px solid rgba(255, 255, 255, 0.18)',
              '@media (max-width: 600px)': { // Mobile styles
                fontSize: '10px',
                padding: '1px 6px',
              }
            }}>
              COMING SOON
            </span> */}
            <a href="https://apps.apple.com/us/app/sail-vacations-social-travel/id6471536080?ign-itscg=30200&ign-itsct=apps_box_link&mttnsubad=6471536080"> <img src={AppStore} className={hero_styles.signup_button} alt="sail_logo" style={{filter: "drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.2))", margin: '0px'}} /></a>
           
          </div>
          {/* <a href="" >
          <img src={AppStore} className={hero_styles.signup_button} alt="sail_logo" style={{filter: "drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.2))", margin: '0px', padding: '10px'}} />
          </a> */}
        <img src={hero}  alt="Sail Hero" />
      </div>
    </div>
  );
};

export default Hero;
