import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo_black.svg';

const Header = () => {
  return (
    <>
      <div className="header-container" style={{position: "fixed", width: "100%", top: 0, zIndex: 1000}}>
        <div className="header">
          <Link to="/" style={{textDecoration: "none", color: "black", display: "flex", flexDirection: "row",  margin: "0", padding: "0"}}>
            <div style={{marginLeft: "10px",marginTop: "20px", display: "flex", alignContent:"center", width: "150px" , justifyContent: "left"}}>
              <img src={logo} alt="Logo" style={{height: "50px", margin: "0", padding: "0"}} />
              <h1 style={{height: "50px", fontSize: "40px", margin: "0", padding: "0", lineHeight:"50px"}}>SAIL</h1>
            </div>
          </Link>
        </div>
      </div>
      <div className='header-background' style={{position: "fixed", height: "100px", width: "100%", backdropFilter: "blur(10px)", top: 0, zIndex: 999}}>
      </div>
    </>
  );
};

export default Header;